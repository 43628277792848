import React from 'react';
import { Card, ListGroup, Button } from 'react-bootstrap';

import FacebookImg from '../../assets/images/fb.jpg';
import GoogleImg from '../../assets/images/gl.png';

const ConnectedAccount = ({ facebook, google }) => {
  let fbButton = (
    <Button outline type="button" variant="primary" size="sm">
      Connect
    </Button>
  );
  let googleButton = (
    <Button outline type="button" variant="primary" size="sm">
      Connect
    </Button>
  );
  if (facebook) {
    fbButton = (
      <Button outline type="button" variant="primary" size="sm" disabled>
        Connected
      </Button>
    );
  }
  if (google) {
    googleButton = (
      <Button outline type="button" variant="primary" size="sm" disabled>
        Connected
      </Button>
    );
  }
  return (
    <>
      <h5>Connected Accounts</h5>
      <Card>
        <ListGroup>
          {facebook && (
            <ListGroup.Item style={{ padding: 0 }}>
              <div
                className="row d-flex"
                style={{ justifyContent: 'space-between', margin: '5px' }}
              >
                <button className="btn btn-link btn-sm">
                  <img
                    src={FacebookImg}
                    alt=""
                    style={{
                      width: '24px',
                      borderRadius: '4px',
                      marginRight: '10px'
                    }}
                  />
                  <b>Facebook</b>
                </button>
                {fbButton}
              </div>
            </ListGroup.Item>
          )}

          {google && (
            <ListGroup.Item style={{ padding: 0 }}>
              <div
                className="row d-flex"
                style={{ justifyContent: 'space-between', margin: '5px' }}
              >
                <button className="btn btn-link btn-sm">
                  <img
                    src={GoogleImg}
                    alt=""
                    style={{ width: '24px', marginRight: '15px' }}
                  />
                  <b>Google</b>
                </button>
                {googleButton}
              </div>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card>
    </>
  );
};

export default ConnectedAccount;
