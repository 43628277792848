export const FETCH_PLAN_START = 'FETCH_PLAN_START';
export const FETCH_PLAN_SUCCESS = 'FETCH_PLAN_SUCCESS';
export const FETCH_PLAN_FAILURE = 'FETCH_PLAN_FAILURE';

export const CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

export const REACTIVE_SUBSCRIPTION_START = 'REACTIVE_SUBSCRIPTION_START';
export const REACTIVE_SUBSCRIPTION_SUCCESS = 'REACTIVE_SUBSCRIPTION_SUCCESS';
export const REACTIVE_SUBSCRIPTION_FAILURE = 'REACTIVE_SUBSCRIPTION_FAILURE';
