import {
  FETCH_PLAN_START,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAILURE,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  REACTIVE_SUBSCRIPTION_START,
  REACTIVE_SUBSCRIPTION_SUCCESS,
  REACTIVE_SUBSCRIPTION_FAILURE
} from './subscription.types';

export const fetchPlanStart = () => ({
  type: FETCH_PLAN_START
});

export const fetchPlanSuccess = payload => ({
  type: FETCH_PLAN_SUCCESS,
  payload
});

export const fetchPlanFail = error => ({
  type: FETCH_PLAN_FAILURE,
  payload: error
});

export const cancelSubsStart = (email, userid, handleClose) => ({
  type: CANCEL_SUBSCRIPTION_START,
  payload: { email, userid, handleClose }
});

export const cancelSubsSuccess = () => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS
});

export const cancelSubsFail = error => ({
  type: CANCEL_SUBSCRIPTION_FAILURE,
  payload: error
});

export const reactiveSubsStart = (email, userid, handleClose) => ({
  type: REACTIVE_SUBSCRIPTION_START,
  payload: { email, userid, handleClose }
});

export const reactiveSubsSuccess = () => ({
  type: REACTIVE_SUBSCRIPTION_SUCCESS
});

export const reactiveSubsFail = error => ({
  type: REACTIVE_SUBSCRIPTION_FAILURE,
  payload: error
});
