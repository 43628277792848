import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import axios from '../../axios';

import {
  FETCH_PLAN_START,
  CANCEL_SUBSCRIPTION_START,
  REACTIVE_SUBSCRIPTION_START
} from './subscription.types';
import {
  fetchPlanSuccess,
  fetchPlanFail,
  cancelSubsSuccess,
  cancelSubsFail,
  reactiveSubsSuccess,
  reactiveSubsFail
} from './subscription.actions';

import { fetchUserSubscriptionStart } from '../user/user.actions';

export function* fetchPlan() {
  try {
    const result = yield axios.get('subscription/plans');
    yield put(fetchPlanSuccess(result.data.items));
  } catch (err) {
    console.log('error::', err);
    toast.error(err.message);
    yield put(fetchPlanFail([err.message]));
  }
}

export function* cancelSubscription({
  payload: { email, userid, handleClose }
}) {
  if (!userid) {
    toast.error('An unexpected error occured, please try again!');
    return;
  }
  try {
    yield axios.put('subscription/cancel', {
      userid,
      email
    });
    yield put(cancelSubsSuccess());
    // reload user data
    const params = {
      type: 'profile',
      email
    };
    yield put(fetchUserSubscriptionStart(params));

    // modal close
    yield handleClose();
  } catch (err) {
    console.log('error::', err);
    toast.error(err.message);
    // modal close
    yield handleClose();
    yield put(cancelSubsFail([err.message]));
  }
}

export function* reactivateSubscription({
  payload: { email, userid, handleClose }
}) {
  if (!userid) {
    toast.error('An unexpected error occured, please try again!');
    return;
  }
  try {
    yield axios.put('subscription/update', {
      userid
    });
    yield put(reactiveSubsSuccess());

    // reload user data
    const params = {
      type: 'profile',
      email
    };
    yield put(fetchUserSubscriptionStart(params));

    // modal close
    yield handleClose();
  } catch (err) {
    console.log('error::', err);
    toast.error(err.message);
    yield handleClose();
    yield put(reactiveSubsFail([err.message]));
  }
}

export function* watchFetchPlan() {
  yield takeLatest(FETCH_PLAN_START, fetchPlan);
}

export function* watchCancelSubscription() {
  yield takeLatest(CANCEL_SUBSCRIPTION_START, cancelSubscription);
}

export function* watchReactivateSubscription() {
  yield takeLatest(REACTIVE_SUBSCRIPTION_START, reactivateSubscription);
}

export function* subscriptionSagas() {
  yield all([
    call(watchFetchPlan),
    call(watchCancelSubscription),
    call(watchReactivateSubscription)
  ]);
}
