import {
  FETCH_PLAN_START,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAILURE,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  REACTIVE_SUBSCRIPTION_START,
  REACTIVE_SUBSCRIPTION_SUCCESS,
  REACTIVE_SUBSCRIPTION_FAILURE
} from './subscription.types';

const INITIAL_STATE = {
  plans: [],
  loading: false,
  isFetching: false,
  error: null
};

const subscriptionReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PLAN_START:
      return {
        ...state,
        isFetching: true
      };
    case CANCEL_SUBSCRIPTION_START:
    case REACTIVE_SUBSCRIPTION_START:
      return {
        ...state,
        loading: true
      };

    case FETCH_PLAN_SUCCESS:
      return {
        ...state,
        plans: payload,
        isFetching: false
      };

    case CANCEL_SUBSCRIPTION_SUCCESS:
    case REACTIVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case FETCH_PLAN_FAILURE:
    case CANCEL_SUBSCRIPTION_FAILURE:
    case REACTIVE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        error: payload,
        isFetching: false,
        loading: false
      };

    default:
      return state;
  }
};

export default subscriptionReducer;
