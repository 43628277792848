import React from 'react';
import { Card, Accordion } from 'react-bootstrap';

const CollapseCard = ({ eventKey, title, children }) => {
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
        <h4 className="panel-title">
          <a href="#!" role="button">
            {title}
          </a>
        </h4>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className="panel-body">{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default CollapseCard;
