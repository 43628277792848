import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import container from './sucess.container';

const Success = ({
  checkout: { user, plan },
  onCreateUser,
  location,
  history
}) => {
  const sessionId = new URLSearchParams(location.search).get('session_id');
  const type = new URLSearchParams(location.search).get('type');

  useEffect(() => {
    if (sessionId && type === 'register') {
      onCreateUser(
        {
          uid: user?.uid,
          name: user?.name,
          firstname: user?.firstname,
          lastname: user?.lastname,
          email: user?.email,
          password: user?.password,
          planId: plan?.id
        },
        sessionId,
        history
      );
    }
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md="6" className="mx-auto py-4 px-3">
            <Card>
              <Card.Body className="text-center">
                <h4 className="pln">Your Payment is Completed!</h4>
                <p>Please verify your Email</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default container(Success);
