import {
  STORE_NEW_USER_START,
  STORE_NEW_USER_SUCCESS,
  STORE_NEW_USER_FAIL,
  STORE_SUBSCRIPTION_PLAN,
  CHECKOUT_RESET
} from './checkout.types';

const INITIAL_STATE = {
  user: null,
  federated: null,
  plan: null,
  loading: false,
  errors: null
};

const checkoutReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case STORE_NEW_USER_START:
      return {
        ...state,
        loading: true
      };

    case STORE_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload.user,
        federated: payload.federated
      };

    case STORE_NEW_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case STORE_SUBSCRIPTION_PLAN:
      return {
        ...state,
        plan: payload
      };

    case CHECKOUT_RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default checkoutReducer;
