import {
  STORE_NEW_USER_START,
  STORE_SUBSCRIPTION_PLAN,
  CHECKOUT_RESET,
  STORE_NEW_USER_SUCCESS,
  STORE_NEW_USER_FAIL,
  CHANGE_CARD_PAYMENT
} from './checkout.types';

export const storeNewUserStart = (user, federated, history, stripe) => ({
  type: STORE_NEW_USER_START,
  payload: { user, federated, history, stripe }
});
export const storeNewUserSuccess = (user, federated) => ({
  type: STORE_NEW_USER_SUCCESS,
  payload: { user, federated }
});
export const storeNewUserFail = (error) => ({
  type: STORE_NEW_USER_FAIL,
  payload: error
});

export const storeSubscriptionPlan = (plan) => ({
  type: STORE_SUBSCRIPTION_PLAN,
  payload: plan
});

export const changeCardPayment = (customer, subscription, stripe) => ({
  type: CHANGE_CARD_PAYMENT,
  payload: { customer, subscription, stripe }
});

export const checkoutReset = () => ({
  type: CHECKOUT_RESET
});
