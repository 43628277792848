import React from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';
import moment from 'moment';

const UserSubscription = ({
  subscription,
  handleCancelShow,
  handleActiveShow,
  handleChangePayment
}) => {
  let status;
  if (subscription.subscriber_status === 'active') {
    if (subscription.subscription_plan.name === 'Premium') {
      status = '1-Year Subscription';
    } else {
      status = '1-Month Subscription';
    }
  } else if (subscription.subscriber_status === 'trialing') {
    status = '14-Days Trial';
  } else if (subscription.subscriber_status === 'canceled') {
    status = 'Cancelled';
  }
  return (
    <>
      <Row>
        <Col xs="6">
          <h5>Subscriptions</h5>
        </Col>
        <Col xs="6">
          <Button
            variant="link"
            size="sm"
            className="float-right p-0"
            onClick={handleChangePayment}
          >
            Change Card Payment
          </Button>
        </Col>
      </Row>

      <Table size="sm" className="m-0">
        <thead>
          <tr>
            <th>Type</th>
            <th>{subscription.subscription_plan.name}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Status</th>
            <td>{status}</td>
          </tr>
          <tr>
            <th>Start Date</th>
            <td>
              {moment(subscription.subscription_start_date).format(
                'MMMM Do, YYYY'
              )}
            </td>
          </tr>
          <tr>
            <th>End Date</th>
            <td>
              {moment(subscription.subscription_end_date).format(
                'MMMM Do, YYYY'
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      <Row>
        <Col>
          {subscription.subscriber_status !== 'canceled' &&
            !subscription.cancel_at_period_end && (
              <Button
                variant="link"
                size="sm"
                className="float-right p-0"
                onClick={handleCancelShow}
              >
                Cancel
              </Button>
            )}
          {(subscription.subscriber_status === 'canceled' ||
            subscription.cancel_at_period_end) && (
            <Button
              variant="link"
              size="sm"
              className="float-right p-0"
              onClick={handleActiveShow}
            >
              Re-activate
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <em style={{ fontSize: 12 }}>
            After your end date, your card on file will be charged automatically
          </em>
        </Col>
      </Row>
    </>
  );
};

export default UserSubscription;
