import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Subscription from './subscription.component';

import { fetchPlanStart } from '../../store/subscription/subscription.actions';

const SubscriptionContainer = ({ onFetchPlanStart, history }) => {
  useEffect(() => {
    onFetchPlanStart();
  }, [onFetchPlanStart]);

  return <Subscription brand="Subscription" history={history} />;
};

const mapStateToProps = state => ({
  auth: state.auth,
  checkout: state.checkout
});

const mapDispatchToProps = dispatch => ({
  onFetchPlanStart: () => dispatch(fetchPlanStart())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionContainer);
