import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Dashboard from './dashboard.component';
import Spinner from '../../common/spinner/spinner.component';

import { fetchUserSubscriptionStart } from '../../store/user/user.actions';

const DashboardContainer = ({
  auth: { currentUser, isAuthenticated, loading },
  onFetchUserSubscriptionStart
}) => {
  useEffect(() => {
    if (isAuthenticated) {
      onFetchUserSubscriptionStart({
        type: 'profile',
        email: currentUser.email
      });
    }
  }, [onFetchUserSubscriptionStart, isAuthenticated, currentUser]);
  return loading ? <Spinner /> : <Dashboard brand="Dashboard" />;
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  onFetchUserSubscriptionStart: params =>
    dispatch(fetchUserSubscriptionStart(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
