import styled from 'styled-components';

export const ButtonContainer = styled.div`
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0px;
  width: 100%;
  text-align: right;
  margin-top: 0px;
  border-top: none;
  display: flex;
`;
