import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card } from 'react-bootstrap';

import StripeCardElement from '../../components/card-checkout/card-checkout.component';

import Accepted from '../../assets/images/accepted.png';

const Checkout = ({ checkout: { plan } }) => {
  if (!plan) {
    return <Redirect to="/signup/offer" />;
  }
  return (
    <>
      <Container>
        <Row>
          <Col md="6" className="mx-auto py-5">
            <Card bg="light" className="mb-4">
              <Card.Body>
                <Card.Title>Subscribe to {plan.name} plan</Card.Title>
                <p>
                  ${parseFloat(plan.price / 100).toFixed(2)}{' '}
                  <span>per {plan.interval}</span>
                </p>
              </Card.Body>
            </Card>

            <Card>
              <Card.Header>
                <Row>
                  <Col
                    className="d-flex"
                    style={{ justifyContent: 'space-between' }}
                  >
                    <h4 className="text-bold">Payment Details</h4>

                    <img
                      style={{ height: '30px' }}
                      className="img-responsive pull-right"
                      src={Accepted}
                      alt=""
                    />
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <StripeCardElement />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Checkout.propTypes = {
  checkout: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  checkout: state.checkout
});

export default connect(mapStateToProps)(Checkout);
