import {
  FETCH_USER_SUBSCRIPTION_START,
  FETCH_USER_SUBSCRIPTION_SUCCESS,
  FETCH_USER_SUBSCRIPTION_FAILURE
} from './user.types';

export const fetchUserSubscriptionStart = params => ({
  type: FETCH_USER_SUBSCRIPTION_START,
  payload: { params }
});

export const fetchUserSubscriptionSuccess = data => ({
  type: FETCH_USER_SUBSCRIPTION_SUCCESS,
  payload: data
});

export const fetchUserSubscriptionFail = error => ({
  type: FETCH_USER_SUBSCRIPTION_FAILURE,
  payload: error
});
