import React from 'react';

import { FacebookButton } from './facebook-button.styles';

const facebookButton = ({ onClick, disabled }) => {
  return (
    <FacebookButton onClick={onClick} disabled={disabled}>
      <i className="fa fa-facebook" color="#255c95"></i>
      <span>Facebook</span>
    </FacebookButton>
  );
};

export default facebookButton;
