import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import {
  CardElement,
  StripeProvider,
  Elements,
  injectStripe
} from 'react-stripe-elements';

// import './stripe-checkout.styles.css';

import config from '../../config';

import { signupStart } from '../../store/auth/auth.actions';

const { Group, Label, Control } = Form;

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        },
        ...(padding ? { padding } : {})
      },
      invalid: {
        color: '#9e2146'
      }
    }
  };
};

class _CardSplitForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      disabled: false,
      clientSecret: null,
      error: null,
      succeeded: false,
      message: null
    };
  }

  handleChange = (change) => {
    if (change.error) {
      return this.setState({
        error: change.error.message,
        disabled: true
      });
    }
    this.setState({
      error: null,
      disabled: false
    });
  };

  handleSubmit = async (ev) => {
    ev.preventDefault();

    if (this.props.stripe) {
      try {
        const payload = await this.props.stripe.createToken();

        if (payload.error) {
          this.setState({
            error: `Charge failed: ${payload.error.message}`,
            disabled: true
          });
        } else {
          const userData = this.props.checkout.user;
          const planData = this.props.checkout.plan;

          this.props.onCreateUser(
            userData,
            planData.stripe_plan,
            payload.token,
            this.props.history
          );
        }
      } catch (error) {
        console.log('error::', error);
        this.setState({
          error: 'Unable to create token',
          disabled: true
        });
      }
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  inputChangeHandler = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };
  render() {
    const { name } = this.state;
    const { loading } = this.props.auth;

    return (
      <form onSubmit={this.handleSubmit}>
        <Group>
          <Label>Card Number</Label>

          <CardElement
            hidePostalCode
            onChange={this.handleChange}
            {...createOptions(this.props.fontSize)}
          />
        </Group>

        <Group controlId="formBasicName">
          <Label>Name of Card</Label>
          <Control
            type="text"
            placeholder="Enter Name"
            name="name"
            value={name}
            onChange={this.inputChangeHandler}
            required
          />
        </Group>

        <div className="error text-center" role="alert">
          <small className="text-danger">{this.state.error}</small>
        </div>

        <div className="text-center mb-3">
          <Button
            type="submit"
            variant="danger"
            block
            disabled={loading || this.state.disabled}
          >
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{' '}
                Processing...
              </>
            ) : (
              <span id="button-text">Start Subscription</span>
            )}
          </Button>
        </div>

        <div className="text-center">
          <small className="text-muted">
            Your card will be immediately charged after trial period
          </small>
          <br />
          <small className="text-muted">
            No refunds for subscriptions after trial ends or on renewal date,
            please cancel your trial or subscription before then as we are
            unable to process refunds
          </small>
        </div>
      </form>
    );
  }
}
const CardSplitForm = injectStripe(_CardSplitForm);

class Checkout extends React.Component {
  constructor() {
    super();
    this.state = {
      elementFontSize: window.innerWidth < 450 ? '14px' : '18px'
    };
    window.addEventListener('resize', () => {
      if (window.innerWidth < 450 && this.state.elementFontSize !== '14px') {
        this.setState({ elementFontSize: '14px' });
      } else if (
        window.innerWidth >= 450 &&
        this.state.elementFontSize !== '18px'
      ) {
        this.setState({ elementFontSize: '18px' });
      }
    });
  }

  render() {
    const { elementFontSize } = this.state;
    return (
      <div className="Checkout">
        <StripeProvider apiKey={config.stripe.publicKey}>
          <Elements>
            <CardSplitForm fontSize={elementFontSize} {...this.props} />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  checkout: state.checkout
});

const mapDispatchToProps = (dispatch) => ({
  onCreateUser: (userData, subscriptionPlan, token, history) =>
    dispatch(signupStart(userData, subscriptionPlan, token, history))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Checkout));
