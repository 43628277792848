import React from 'react';
import { Route } from 'react-router-dom';

import ChoosePlan from '../choose-plan/subscription.container';
import RegisterForm from '../register-form/register-form.component';
import Checkout from '../checkout/checkout.component';

const ShopPage = ({ match }) => {
  return (
    <>
      <Route path={`${match.path}/offer`} component={ChoosePlan} />
      <Route path={`${match.path}/register`} component={RegisterForm} />
      <Route path={`${match.path}/checkout/`} component={Checkout} />
    </>
  );
};

export default ShopPage;
