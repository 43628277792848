import React from 'react';

const UserDetail = ({ currentUser }) => {
  return (
    <>
      <h5>Email</h5>
      <span className="item-sb">{currentUser.email}</span>
    </>
  );
};

UserDetail.propTypes = {};

export default UserDetail;
