import React from 'react';

import { SpinnerContainer } from './spinner.styles';

const spinner = () => (
  <SpinnerContainer>
    <div className="lds-ring">
      <div />
      <div />
      <div />
    </div>
  </SpinnerContainer>
);

export default spinner;
