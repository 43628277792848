export default {
  APP_URL: 'https://listenonrepeat.com',
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_D6DnCDeKR',
    APP_CLIENT_ID: '163ute4r5laeqijr8s23ulagkp',
    IDENTITY_POOL_ID: 'us-east-1:b05905f6-8856-4d11-b99b-a7bd3ce0e6e4'
  },
  stripe: {
    apiUrl: 'https://api.stripe.com/v1',
    currency: 'us',
    publicKey: 'pk_live_6K8g8wKSPM4IlGbplTyZ5s6v00QakWBlaA'
  },
  facebook: {
    API_KEY: '406063126099603'
  },
  google: {
    CLIENT_ID:
      '221980213680-pgdsi5hivopcrg17gqp5das7q8kpqab9.apps.googleusercontent.com',
    SITE_KEY: '6LfSueQbAAAAAF3FnmFMInwmZ_5RjqtcaIfA7GPS'
  }
};
