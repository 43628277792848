import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth/auth.reducer';
import subscriptionReducer from './subscription/subscription.reducer';
import checkoutReducer from './checkout/checkout.reducer';
import userReducer from './user/user.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['checkout']
};

const rootReducer = combineReducers({
  auth: authReducer,
  subscription: subscriptionReducer,
  checkout: checkoutReducer,
  user: userReducer
});
export default persistReducer(persistConfig, rootReducer);
