import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Form,
  Modal
} from 'react-bootstrap';

import OverlaySpinner from '../../common/overlay-spinner/overlay-spinner.component';

const { Group, Label, Control } = Form;

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: ''
  });
  const [loading, setLoading] = useState(false);

  const { email } = formData;

  const inputChangeHandler = event => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const onSubmitHandler = async e => {
    e.preventDefault();

    setLoading(true);

    try {
      await Auth.forgotPassword(email);
      toast.success('Password rest link has been sent to your email');
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <OverlaySpinner />}
      <Container>
        <Row>
          <Col md="6" className="mx-auto py-5">
            <Card className="px-4">
              <Card.Body>
                <form onSubmit={onSubmitHandler}>
                  <Group controlId="formBasicEmail">
                    <Label>Email</Label>
                    <Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      onChange={inputChangeHandler}
                    />
                  </Group>

                  <Group>
                    <Button variant="danger" type="submit" block>
                      Submit
                    </Button>
                  </Group>
                </form>

                <Modal.Footer className="pt-3 mb-1">
                  <p className="font-small grey-text d-flex justify-content-end">
                    Already a Member?
                    <Link to="/login" className="blue-text ml-1">
                      Sign In
                    </Link>
                  </p>
                </Modal.Footer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
