import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

import axios from '../../axios';

import {
  SIGN_UP_START,
  SIGN_IN_START,
  SIGN_OUT_START,
  FACEBOOK_SIGN_IN_START,
  GOOGLE_SIGN_IN_START
} from './auth.types';
import {
  signinSuccess,
  signinFail,
  signupSuccess,
  signupFail,
  signoutSuccess,
  signoutFail
} from './auth.actions';

import { checkoutReset } from '../checkout/checkout.actions';

const getCheckoutState = (state) => state.checkout;

export function* signin({
  payload: {
    formData: { email, password },
    history
  }
}) {
  try {
    const { username, attributes } = yield Auth.signIn(email, password);

    yield put(
      signinSuccess({
        username,
        email: attributes.email,
        userid: attributes['custom:userid']
      })
    );
    yield history.push({ pathname: '/dashboard' });
  } catch (err) {
    console.log('error::', err);
    if (err.code === 'UserNotConfirmedException') {
      toast.error('Please verify email your first!!');
    } else {
      toast.error('Invalid Email or Password!');
    }
    yield put(signinFail(err.message));
  }
}

export function* fbSignin({ payload: { user, token, expires_at, history } }) {
  try {
    const { data } = yield axios.get('users/check', {
      params: {
        email: user.email
      }
    });

    if (data.message === 'success') {
      yield put(signinFail(['User Not Found']));
      toast.error('Please Signup before continue');
      return;
    }

    try {
      yield Auth.federatedSignIn('facebook', { token, expires_at }, user);
      yield put(signinSuccess(user));
      history.push({ pathname: '/dashboard' });
    } catch (err) {
      console.log('error::', err);
      yield put(signinFail(err.message));
    }
  } catch (error) {
    console.log(error);
    yield put(signinFail(error.message));
  }
}

export function* googleSignin({
  payload: { user, token, expires_at, history }
}) {
  try {
    const { data } = yield axios.get('users/check', {
      params: {
        email: user.email
      }
    });
    if (data.message === 'success') {
      yield put(signinFail(['User Not Found']));
      toast.error('Please Signup before continue');
      return;
    }

    try {
      yield Auth.federatedSignIn('google', { token, expires_at }, user);
      yield put(signinSuccess(user));
      yield history.push({ pathname: '/dashboard' });
    } catch (err) {
      console.log('error::', err);
      yield put(signinFail(err.message));
    }
  } catch (error) {
    console.log(error);
    yield put(signinFail(error.message));
  }
}

export function* saveToDB(data, history) {
  try {
    yield axios.post('users/create', data);
    localStorage.removeItem('auth::register');
    toast.success('Successfully subscribed to plan!');
    yield put(signupSuccess());
    yield put(checkoutReset());
    yield awsFederatedSignin(data, history);
  } catch (err) {
    console.log('error::', err);
    toast.error('An unexpected error occured, please try again!');
    yield put(signupFail(err.message));
  }
}

export function* awsFederatedSignin(data, history) {
  const checkoutState = yield select(getCheckoutState);
  const { token, expires_at, type } = checkoutState.federated;

  let user = null;
  switch (type) {
    case 'facebook':
      user = {
        email: data.email,
        fbUid: data.fbUid
      };
      break;
    case 'google':
      user = {
        email: data.email,
        googleUid: data.googleUid
      };
      break;

    default:
      break;
  }
  if (user) {
    try {
      const response = yield Auth.federatedSignIn(
        type,
        { token, expires_at },
        user
      );
      console.log({ response });
      localStorage.removeItem('auth::social::login');
      yield put(signinSuccess(user));
      yield history.push({ pathname: '/dashboard' });
    } catch (err) {
      console.log('error::', err);
      yield put(signinFail(err.message));
      yield history.push({ pathname: '/' });
    }
  }
}

export function* emailSignup(data, history) {
  try {
    yield Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        email: data.email,
        'custom:userid': data.uid
      }
    });

    try {
      yield axios.post('users/create', data);

      localStorage.removeItem('auth::register');
      toast.success('Successfully created account!');
      yield history.push({ pathname: '/login' });
      yield put(signupSuccess());
      yield put(checkoutReset());
    } catch (err) {
      console.log('error::', err);
      yield history.push({ pathname: '/cancel' });
      toast.error(err.message);
      yield put(signupFail(err.message));
    }
  } catch (e) {
    console.log('error::', e);
    yield history.push({ pathname: '/cancel' });
    toast.error(e.message);
    yield put(signupFail(e.message));
  }
}

export function* signup({ payload: { formData, sessionid, history } }) {
  const data = {
    uid: formData.uid,
    email: formData.email,
    planId: formData.planId,
    sessionid
  };

  if (formData.type === 'facebook') {
    data.fbUid = formData.fbUid;
    yield saveToDB(data, history);
    return;
  }

  if (formData.type === 'google') {
    data.googleUid = formData.googleUid;
    yield saveToDB(data, history);
    return;
  }

  data.password = formData.password;
  yield emailSignup(data, history);
}

export function* signOut() {
  try {
    yield Auth.signOut();
    yield put(signoutSuccess());
  } catch (err) {
    console.log('error::', err);
    yield put(signoutFail(err));
  }
}

export function* watchSignin() {
  yield takeLatest(SIGN_IN_START, signin);
}

export function* watchFbSignin() {
  yield takeLatest(FACEBOOK_SIGN_IN_START, fbSignin);
}

export function* watchGoogleSignin() {
  yield takeLatest(GOOGLE_SIGN_IN_START, googleSignin);
}

export function* watchSignup() {
  yield takeLatest(SIGN_UP_START, signup);
}

export function* watchSignout() {
  yield takeLatest(SIGN_OUT_START, signOut);
}

export function* authSagas() {
  yield all([
    call(watchSignin),
    call(watchFbSignin),
    call(watchGoogleSignin),
    call(watchSignup),
    call(watchSignout)
  ]);
}
