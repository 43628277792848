import { takeLatest, call, put, all } from 'redux-saga/effects';
import axios from '../../axios';

import { FETCH_USER_SUBSCRIPTION_START } from './user.types';
import {
  fetchUserSubscriptionSuccess,
  fetchUserSubscriptionFail
} from './user.actions';

export function* fetchUserSubscription({ payload: { params } }) {
  try {
    const { data } = yield axios.get('/subscription/users', { params });
    yield put(fetchUserSubscriptionSuccess(data.items));
  } catch (err) {
    console.log('error::', err);
    yield put(fetchUserSubscriptionFail(err.errors));
  }
}

export function* watchFetchUserSubscription() {
  yield takeLatest(FETCH_USER_SUBSCRIPTION_START, fetchUserSubscription);
}

export function* userSagas() {
  yield all([call(watchFetchUserSubscription)]);
}
