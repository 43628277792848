import { connect } from 'react-redux';

import { signupStart } from '../../store/auth/auth.actions';

const mapStateToProps = (state) => ({
  auth: state.auth,
  checkout: state.checkout
});

const mapDispatchToProps = (dispatch) => ({
  onCreateUser: (userData, sessionId, history) =>
    dispatch(signupStart(userData, sessionId, history))
});
const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
