import React from 'react';

import { GoogleButton } from './google-button.styles';

const googleButton = ({ onClick, disabled }) => {
  return (
    <GoogleButton onClick={onClick} disabled={disabled}>
      <i className="fa fa-google-plus" color="#f1403a"></i>
      <span>Google</span>
    </GoogleButton>
  );
};

export default googleButton;
