import styled from 'styled-components';

export const GoogleButton = styled.button`
  border: 10px;
  box-sizing: border-box;
  display: inline-block;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  text-decoration: none;
  margin: 0px;
  padding: 0px;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  position: relative;
  height: 36px;
  line-height: 36px;
  min-width: 40px;
  color: rgb(241, 64, 58);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  border-radius: 0px;
  user-select: none;
  overflow: hidden;
  background: linear-gradient(
    to left,
    rgba(253, 116, 108, 0.3),
    rgba(255, 144, 104, 0.3)
  );
  text-align: center;
  width: 50%;

  i {
    vertical-align: middle;
    margin-left: 12px;
    margin-right: 0px;
    color: #f1403a;
  }

  span {
    position: relative;
    padding-left: 8px;
    padding-right: 16px;
    vertical-align: middle;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }
`;
