import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import axios from '../../axios';

import { STORE_NEW_USER_START, CHANGE_CARD_PAYMENT } from './checkout.types';
import { storeNewUserSuccess, storeNewUserFail } from './checkout.actions';

export function* storeNewUser({
  payload: { user, federated, history, stripe }
}) {
  const { uid, email, planId } = user;
  try {
    const { data } = yield axios.get('users/check', {
      params: {
        email
      }
    });

    if (data.message === 'success') {
      yield put(storeNewUserSuccess(user, federated));
      // Call your backend to create the Checkout Session
      const { data } = yield axios.post(
        'subscription/create-checkout-session',
        {
          uid,
          email,
          planId,
          fbUid: user?.fbUid,
          googleUid: user?.googleUid,
          type: 'register'
        }
      );

      console.log({ data });

      // When the customer clicks on the button, redirect them to Checkout.
      const result = yield stripe.redirectToCheckout({
        sessionId: data.sessionId
      });

      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        console.error(result.error.message);
      }
      return;
    }
    yield put(storeNewUserFail(['Email Already Exists']));
    toast.error('Email Already Exists');
    history.push('/login');
  } catch (error) {
    console.log({ error });
    yield put(storeNewUserFail(['Email Already Exists']));
  }
}

export function* changeCardPaymentAsync({
  payload: { customer, subscription, stripe }
}) {
  // Call your backend to create the Checkout Session
  const { data } = yield axios.post('subscription/update-card-session', {
    customer,
    subscription
  });

  console.log({ data });

  // When the customer clicks on the button, redirect them to Checkout.
  const result = yield stripe.redirectToCheckout({
    sessionId: data.sessionId
  });

  if (result.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
    console.error(result.error.message);
  }
}

export function* watchStoreNewUser() {
  yield takeLatest(STORE_NEW_USER_START, storeNewUser);
}

export function* watchCardPayment() {
  yield takeLatest(CHANGE_CARD_PAYMENT, changeCardPaymentAsync);
}

export function* checkoutSagas() {
  yield all([call(watchStoreNewUser), call(watchCardPayment)]);
}
