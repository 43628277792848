import { all, call } from 'redux-saga/effects';

import { authSagas } from './auth/auth.sagas';
import { checkoutSagas } from './checkout/checkout.sagas';
import { subscriptionSagas } from './subscription/subscription.sagas';
import { userSagas } from './user/user.sagas';

export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(subscriptionSagas),
    call(userSagas),
    call(checkoutSagas)
  ]);
}
