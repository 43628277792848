import React from 'react';

import { GobackContainer, BodyText } from './go-back.styles';

const goback = ({ text, clicked }) => {
  return (
    <GobackContainer onClick={clicked}>
      <BodyText>
        <i className="fa fa-angle-left mr-1" aria-hidden="true" />
        {text}
      </BodyText>
    </GobackContainer>
  );
};

export default goback;
