import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import OverlaySpinner from '../../common/overlay-spinner/overlay-spinner.component';

const { Group, Label, Control } = Form;

const ResetPassword = props => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: '',
    password2: ''
  });

  const { password, password2 } = formData;

  const inputChangeHandler = event => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleResetPasswordSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const code = new URLSearchParams(props.location.search).get(
      'confirmation_code'
    );
    const username = new URLSearchParams(props.location.search).get(
      'user_name'
    );
    if (password !== password2) {
      toast.error('password donot match!!');
      setLoading(false);
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(username, code, password);

      setLoading(false);
      toast.success('Password reset successfully');
      props.history.push('/login');
    } catch (e) {
      setLoading(false);
      toast.error(e.message);
    }
  };

  return (
    <>
      {loading && <OverlaySpinner />}
      <Container>
        <Row>
          <Col md="6" className="mx-auto py-5">
            <Card className="px-4">
              <Card.Body>
                <div className="text-center">
                  <h3 className="dark-grey-text mb-5">
                    <strong>Reset Password</strong>
                  </h3>
                </div>
                <form onSubmit={handleResetPasswordSubmit}>
                  <div className="grey-text">
                    <Group controlId="newPassword">
                      <Label>New Password</Label>
                      <Control
                        placeholder="Enter Password"
                        type="password"
                        name="password"
                        onChange={inputChangeHandler}
                        required
                        minLength={6}
                      />
                    </Group>
                    <Group className="item-pq" controlId="confirmPassword">
                      <Label>Confirm Password</Label>
                      <Control
                        type="password"
                        placeholder="Confirm Password"
                        name="password2"
                        value={password2}
                        onChange={inputChangeHandler}
                        required
                        minLength={6}
                      />
                    </Group>
                  </div>
                  <div className="text-center mb-3">
                    <Group>
                      <Button
                        variant="danger"
                        type="submit"
                        block
                        className="btn-rounded"
                      >
                        Submit
                      </Button>
                    </Group>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
