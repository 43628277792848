import styled from 'styled-components';

export const GobackContainer = styled.div`
  margin-top: 20px;
  cursor: pointer;
`;

export const BodyText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  color: #c4c4c4;
`;
