import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import NavHeader from './components/header/header.component';
import Routes from './routes';

import { store, persistor } from './store';

import config from './config';

import {
  loadUserStart,
  loadUserSuccess,
  loadUserFail
} from './store/auth/auth.actions';

function waitForInitFB() {
  return new Promise((res, rej) => {
    const hasFbLoaded = () => {
      if (window.FB) {
        res();
      } else {
        setTimeout(hasFbLoaded, 300);
      }
    };
    hasFbLoaded();
  });
}

function waitForInitGoogle() {
  return new Promise((res, rej) => {
    const hasGoogleSDKLoaded = () => {
      if (window.gapi) {
        res();
      } else {
        setTimeout(hasGoogleSDKLoaded, 300);
      }
    };
    hasGoogleSDKLoaded();
  });
}

function App() {
  useEffect(() => {
    async function loadUser() {
      store.dispatch(loadUserStart());
      try {
        await Auth.currentSession();
        const { username, attributes } = await Auth.currentAuthenticatedUser();
        const data = {
          username,
          email: attributes.email
        };
        const userid = attributes['custom:userid'];
        if (userid) {
          data.userid = userid;
        }
        store.dispatch(loadUserSuccess(data));
      } catch (err) {
        if (err.trim && err.trim() !== 'No current user') {
          store.dispatch(loadUserFail(err.message));
        } else {
          const federatedInfo = JSON.parse(
            localStorage.getItem('aws-amplify-federatedInfo')
          );

          if (federatedInfo) {
            if (federatedInfo.provider === 'facebook') {
              await waitForInitFB();
              const fb = window.FB;
              fb.api(
                '/me',
                'get',
                {
                  access_token: federatedInfo.token,
                  fields: 'email, name, first_name, last_name, gender'
                },
                userData => {
                  store.dispatch(
                    loadUserSuccess({
                      name: userData.name,
                      email: userData.email,
                      fbUid: userData.id
                    })
                  );
                }
              );
            }

            if (federatedInfo.provider === 'google') {
              await waitForInitGoogle();
              axios
                .get('https://oauth2.googleapis.com/tokeninfo', {
                  params: {
                    id_token: federatedInfo.token
                  }
                })
                .then(({ data }) => {
                  store.dispatch(
                    loadUserSuccess({
                      name: data.name,
                      email: data.email,
                      googleUid: data.sub
                    })
                  );
                })
                .catch(e => {
                  console.log(e);
                  store.dispatch(loadUserFail('No current user'));
                });
            }
          } else {
            store.dispatch(loadUserFail('No current user'));
          }
        }
      }
    }

    loadFacebookSDK();
    loadGoogleSDK();
    loadUser();
  }, []);

  const loadFacebookSDK = () => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: config.facebook.API_KEY,
        cookie: true,
        xfbml: true,
        version: 'v5.0'
      });

      window.FB.AppEvents.logPageView();
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  const loadGoogleSDK = () => {
    window['googleSDKLoaded'] = () => {
      window.gapi.load('auth2', function() {
        window.gapi.auth2.init({
          client_id: config.google.CLIENT_ID,
          scope: 'profile email'
        });
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  };

  return (
    <Provider store={store}>
      <Router>
        <PersistGate persistor={persistor}>
          <NavHeader />
          <Routes />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </PersistGate>
      </Router>
    </Provider>
  );
}

export default App;
