import React from 'react';
import { Container, Accordion } from 'react-bootstrap';

import SignUpImg from '../../assets/images/signupbutton.png';
import SelectImg from '../../assets/images/select package.png';
import RegisterImg from '../../assets/images/register.png';
import PaymentImg from '../../assets/images/payemnet dateilas.png';
import AfterImg from '../../assets/images/gbli.png';
import CancelImg from '../../assets/images/cancel.png';
import CancelSubmitImg from '../../assets/images/cancel-submit.png';

import { CollapseCard } from './components';

import './faq.styles.scss';

const Faq = () => {
  return (
    <Container className="faq-wrapper mx-auto py-4 px-3">
      <div className="wrapper center-block">
        <div className="headerbox">
          <h3>Frequently Asked Questions (FAQ)</h3>
          <span>Why subscribe?</span>
        </div>

        <Accordion defaultActiveKey="0" className="panel-group">
          <CollapseCard
            eventKey="0"
            title="1. What is the ListenOnRepeat Subscription feature ?"
          >
            <ul>
              <li>
                ListenOnRepeat Subscription feature allows for an Ad free
                experience with better performance.
              </li>
            </ul>
          </CollapseCard>
          <CollapseCard
            eventKey="1"
            title="2. How can I use ListenOnRepeat without using Subscription Feature?"
          >
            <ul>
              <li>
                To use ListenOnRepeat without using the Subscription feature ,
                just disable ad blockers in ListenOnRepeat.
              </li>
            </ul>
          </CollapseCard>
          <CollapseCard
            eventKey="2"
            title="3. How can I subscribe to ListenOnRepeat Subscription Feature ?
            "
          >
            <ul>
              <li>
                Go to url :{' '}
                <a href="https://subscription.listenonrepeat.com/login">
                  https://subscription.listenonrepeat.com/login
                </a>
              </li>
              <li>
                Click on Sign Up
                <img src={SignUpImg} alt="" />
              </li>
              <li>
                Choose any of Basic or Premium Plan and click select.
                <img src={SelectImg} alt="" />
              </li>
              <li>
                Sign up using any of the preferred methods among Google,
                Facebook or Email.
                <img src={RegisterImg} alt="" />
              </li>
              <li>
                Enter your payment details and click on Start Subscription
                <img src={PaymentImg} alt="" />
              </li>
              <li>Login to Subscription feature to activate your account.</li>
              <li>
                Click on Go back to the ListenOnRepeat button to activate
                ListenOnRepeat Subscription feature.
                <img src={AfterImg} alt="" />
              </li>
              <li>
                You will be directed to the ListenOnRepeat site. You’re ready to
                go Ads free.
              </li>
            </ul>
            <strong>
              Note: Your account will not activate until you Login to
              Subscription Feature and click on Go back to ListenOnRepeat button
            </strong>
          </CollapseCard>
          <CollapseCard
            eventKey="3"
            title="4. How can I cancel my Subscription ?"
          >
            <ul>
              <li>
                Go to url :{' '}
                <a href="https://subscription.listenonrepeat.com/login">
                  https://subscription.listenonrepeat.com/login
                </a>
              </li>
              <li>Login to subscription feature.</li>
              <li>
                Click on the Cancel button.
                <img src={CancelImg} alt="" />
              </li>
              <li>
                Click on Submit to confirm cancellation.
                <img src={CancelSubmitImg} alt="" />
              </li>
            </ul>
          </CollapseCard>
          <CollapseCard
            eventKey="4"
            title="5. Will subscribing to the Subscription feature automatically block ads in the ListenOnRepeat site ?"
          >
            <ul>
              <li>
                The subscription feature will allow you to block ads using
                AdBlockers but will not automatically block ads from displaying
                in ListenOnRepeat. An ad blocker is required for the Ad free
                subscription to work without ads.
              </li>
            </ul>
          </CollapseCard>
          <CollapseCard
            eventKey="5"
            title="6. Ads are still being displayed even after subscribing to ListenOnRepeat subscription feature."
          >
            <ul>
              <li>
                The subscription feature will allow you to block ads using
                AdBlockers but will not automatically block ads from displaying
                in ListenOnRepeat. An ad blocker is required for the Ad free
                subscription to work without ads.
              </li>
            </ul>
            <strong>
              Note: Your account will not activate until you Login to
              Subscription Feature and click on Go back to ListenOnRepeat button
            </strong>
          </CollapseCard>
          <CollapseCard
            eventKey="6"
            title="7. Will I get a refund if I cancel my subscriptions?"
          >
            <ul>
              <li>
                We encourage our users to try the LOR subscription and offer a 14
                day trial period for the new users. You will be eligible for
                refunds if you cancel the subscription within the trial period.
              </li>
            </ul>
            <strong>
              Note: No refunds for subscriptions after trial ends or on renewal
              date, please cancel your trial or subscription before then as we
              are unable to process refunds
            </strong>
          </CollapseCard>
        </Accordion>
      </div>
    </Container>
  );
};

export default Faq;
