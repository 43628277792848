import {
  FETCH_USER_SUBSCRIPTION_START,
  FETCH_USER_SUBSCRIPTION_SUCCESS,
  FETCH_USER_SUBSCRIPTION_FAILURE
} from './user.types';

const INITIAL_STATE = {
  user_subscription: null,
  loading: false,
  error: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER_SUBSCRIPTION_START:
      return {
        ...state,
        loading: true
      };

    case FETCH_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        user_subscription: payload,
        loading: false
      };

    case FETCH_USER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default userReducer;
