import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import uuid from 'uuid';

import axios from '../../axios';
import config from '../../config';

import OverlaySpinner from '../../common/overlay-spinner/overlay-spinner.component';
import GoBack from '../../common/go-back/go-back.component';
import FacebookButton from '../../common/facebook-button/facebook-button.component';
import GoogleButton from '../../common/google-button/google-button.component';

import container from './register-form.container';

import { ButtonContainer } from './register-form.styles';

const { Group, Label, Control } = Form;

const Signup = ({ checkout: { loading, plan }, onStoreNewUser, history }) => {
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const stripePromise = loadStripe(config?.stripe?.publicKey);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${config.google.SITE_KEY}`,
      function () {
        console.log('Script loaded!');
      }
    );
  }, []);

  const { email, password } = formData;

  const inputChangeHandler = (event) => {
    const { value, name } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const stripe = await stripePromise;
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(config.google.SITE_KEY, { action: 'submit' })
        .then((token) => {
          verifyCaptcha(token, stripe);
        });
    });
  };

  const verifyCaptcha = (token, stripe) => {
    // Get Stripe.js instance
    return axios
      .post('/users/verifyRecaptcha', { token })
      .then((response) => {
        localStorage.setItem('auth::register', true);
        onStoreNewUser(
          {
            ...formData,
            uid: uuid.v1(),
            planId: plan.stripe_plan
          },
          null,
          history,
          stripe
        );
      })
      .catch((err) => console.log(err));
  };

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      handleResponseFacebook(response.authResponse);
    } else {
      handleError(response);
    }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(statusChangeCallback);
  };

  const handleClick = () => {
    setLoading(!isLoading);
    window.FB.login(checkLoginState, { scope: 'public_profile, email' });
  };

  const onSuccess = () => {
    const ga = window.gapi.auth2.getAuthInstance();
    ga.signIn().then(
      (googleUser) => {
        handleResponseGoogle(googleUser);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleResponseGoogle = async (googleUser) => {
    const { id_token, expires_at } = googleUser.getAuthResponse();
    const profile = googleUser.getBasicProfile();

    const stripe = await stripePromise;

    const user = {
      uid: uuid.v1(),
      type: 'google',
      googleUid: profile.getId(),
      email: profile.getEmail(),
      firstname: profile.getGivenName(),
      lastname: profile.getFamilyName(),
      planId: plan.stripe_plan
    };

    const federated = {
      token: id_token,
      expires_at,
      type: 'google'
    };

    localStorage.setItem('auth::register', true);
    localStorage.setItem('auth::social::login', JSON.stringify(federated));

    onStoreNewUser(user, federated, history, stripe);
  };

  function fbApi(token) {
    return new Promise(async (resolve) => {
      window.FB.api(
        '/me',
        'get',
        {
          access_token: token,
          fields: 'email, name, first_name, last_name, gender'
        },
        (response) => {
          resolve(response);
        }
      );
    });
  }

  const handleResponseFacebook = async (data) => {
    const { accessToken: token, expiresIn } = data;
    const expires_at = expiresIn * 1000 + new Date().getTime();

    setLoading(!isLoading);

    const userData = await fbApi(token);

    const stripe = await stripePromise;

    const user = {
      uid: uuid.v1(),
      type: 'facebook',
      firstname: userData.first_name,
      lastname: userData.last_name,
      gender: userData.gender,
      email: userData.email,
      fbUid: userData.id,
      planId: plan.stripe_plan
    };

    const federated = {
      token,
      expires_at,
      type: 'facebook'
    };

    localStorage.setItem('auth::register', true);
    localStorage.setItem('auth::social::login', JSON.stringify(federated));
    onStoreNewUser(user, federated, history, stripe);
  };

  const handleError = (error) => {
    setLoading(false);
    console.log('error::', error);
  };

  return (
    <>
      {loading && <OverlaySpinner />}
      <Container>
        <GoBack clicked={() => history.goBack()} text="Back" />
        <Row>
          <Col md="6" className="mx-auto py-5">
            <Card className="px-4">
              <Card.Body>
                <div className="text-center">
                  <h3 className="dark-grey-text mb-5">
                    <strong>Register</strong>
                  </h3>
                </div>
                <form onSubmit={onSubmitHandler}>
                  <div className="grey-text">
                    <Group controlId="formBasicEmail">
                      <Label>Email</Label>
                      <Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        value={email}
                        onChange={inputChangeHandler}
                      />
                    </Group>
                    <Group className="item-pq" controlId="formBasicPassword">
                      <Label>Password</Label>
                      <Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={inputChangeHandler}
                        minLength={6}
                      />
                    </Group>
                  </div>
                  <div className="text-center mb-3">
                    <Group>
                      <Button
                        variant="danger"
                        type="submit"
                        block
                        className="btn-rounded"
                      >
                        Submit
                      </Button>
                    </Group>
                  </div>
                </form>
                <p className="font-small dark-grey-text text-right d-flex justify-content-center mb-3 pt-2">
                  or Sign up with:
                </p>
                <ButtonContainer className="my-3">
                  <FacebookButton onClick={handleClick} disabled={isLoading} />
                  <GoogleButton onClick={onSuccess} disabled={isLoading} />
                </ButtonContainer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Signup.propTypes = {
  onStoreNewUser: PropTypes.func.isRequired
};

export default container(Signup);
