import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Navbar, Nav, Container } from 'react-bootstrap';

import Logo from '../../assets/images/logo-2x.png';

import { signoutStart } from '../../store/auth/auth.actions';

const Header = ({
  brand,
  route,
  auth: { currentUser, isAuthenticated },
  onSignoutStart
}) => {
  const handleLogout = async event => {
    if (window.FB) {
      const fb = window.FB;
      fb.getLoginStatus(function(response) {
        fb.logout(function(response) {
          console.log('Logged Out!');
        });
      });
    }
    if (window.gapi) {
      var auth2 = window.gapi.auth2.getAuthInstance();

      auth2
        .signOut()
        .then(() => {})
        .catch(err => console.log(err));
    }

    await onSignoutStart();
  };
  return (
    <Navbar variant="dark" className="justify-content-between" expand="lg">
      <Container>
        <Navbar.Brand href="/" style={{ cursor: 'pointer' }}>
          <img src={Logo} alt="Logo" />
        </Navbar.Brand>

        <Nav>
          {isAuthenticated && (
            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  onSignoutStart: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  onSignoutStart: () => dispatch(signoutStart())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
