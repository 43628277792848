import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import OverlaySpinner from '../../common/overlay-spinner/overlay-spinner.component';
import FacebookButton from '../../common/facebook-button/facebook-button.component';
import GoogleButton from '../../common/google-button/google-button.component';

import axios from '../../axios';
import config from '../../config';

import {
  facebookSignInStart,
  googleSignInStart,
  signinStart,
  signinSuccess,
  signinFail
} from '../../store/auth/auth.actions';

import { ButtonContainer } from './sign-in.styles';

const { Group, Label, Control } = Form;

const Signin = ({
  onSigninStart,
  onSigninStartFB,
  onSigninStartGoogle,
  history,
  auth: { loading }
}) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [isLoading, setLoading] = useState(false);

  const { email, password } = formData;

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${config.google.SITE_KEY}`,
      function () {
        console.log('Script loaded!');
      }
    );
  }, []);

  const inputChangeHandler = (event) => {
    const { value, name } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(config.google.SITE_KEY, { action: 'submit' })
        .then((token) => {
          verifyCaptcha(token);
        });
    });
  };

  const statusChangeCallback = (response) => {
    if (response.status === 'connected') {
      handleResponseFacebook(response.authResponse);
    } else {
      handleError(response);
    }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(statusChangeCallback);
  };

  const handleClick = (e) => {
    setLoading(!isLoading);
    window.FB.login(checkLoginState, { scope: 'public_profile,email' });
  };

  const verifyCaptcha = async (token) => {
    console.log(token);
    return axios
      .post('/users/verifyRecaptcha', { token })
      .then((response) => {
        onSigninStart({ email, password }, history);
      })
      .catch((err) => console.log(err));
  };

  const handleError = (error) => {
    setLoading(false);
    console.log('error::', error);
  };

  function fbApi() {
    return new Promise(async (resolve) => {
      window.FB.api(
        '/me',
        {
          fields: 'email, name, first_name, last_name, gender'
        },
        (response) => {
          resolve(response);
        }
      );
    });
  }

  const handleResponseFacebook = async (data) => {
    const { accessToken: token, expiresIn } = data;
    const expires_at = expiresIn * 1000 + new Date().getTime();

    const userData = await fbApi();

    const user = {
      name: userData.name,
      email: userData.email,
      fbUid: userData.id
    };

    onSigninStartFB(user, token, expires_at, history);
  };

  const onSuccess = () => {
    const ga = window.gapi.auth2.getAuthInstance();
    ga.signIn().then(
      (googleUser) => {
        getAWSCredentials(googleUser);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAWSCredentials = async (googleUser) => {
    const { id_token, expires_at } = googleUser.getAuthResponse();
    const profile = googleUser.getBasicProfile();
    let user = {
      email: profile.getEmail(),
      name: profile.getName(),
      googleUid: profile.getId()
    };

    onSigninStartGoogle(user, id_token, expires_at, history);
  };

  return (
    <>
      {loading && <OverlaySpinner />}

      <Container>
        <Row>
          <Col md="6" className="mx-auto py-5">
            <Card className="px-4">
              <Card.Body>
                <div className="text-center">
                  <h3 className="dark-grey-text">Login</h3>
                </div>
                <form onSubmit={onSubmitHandler}>
                  <div className="grey-text">
                    <Group controlId="formBasicEmail">
                      <Label>Email</Label>
                      <Control
                        type="email"
                        placeholder="Enter email"
                        name="email"
                        value={email}
                        onChange={inputChangeHandler}
                      />
                    </Group>
                    <Group className="item-pq" controlId="formBasicPassword">
                      <Label>Password</Label>
                      <Control
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={inputChangeHandler}
                        minLength={6}
                      />
                    </Group>
                  </div>
                  <Row className="pb-2">
                    <Col>
                      <Link
                        to="/forgot-password"
                        className="float-right link-r"
                      >
                        Forgot Password?
                      </Link>
                    </Col>
                  </Row>
                  <div className="text-center mb-3">
                    <Group>
                      <Button
                        variant="danger"
                        type="submit"
                        block
                        className="btn-rounded"
                      >
                        Submit
                      </Button>
                    </Group>
                  </div>
                </form>
                <p className="font-small dark-grey-text text-right d-flex justify-content-center mb-3 pt-2">
                  or Sign in with:
                </p>
                <ButtonContainer className="my-3">
                  <FacebookButton onClick={handleClick} disabled={isLoading} />
                  <GoogleButton onClick={onSuccess} disabled={isLoading} />
                </ButtonContainer>

                <hr />
                <Row className="d-flex align-items-center">
                  <Col md="6">
                    <small>
                      <Link to="/faq">Frequently Asked Questions</Link>
                    </small>
                  </Col>
                  <Col md="6">
                    <p className="font-small d-flex justify-content-end">
                      Not a member?
                      <Link to="/signup/offer" className="blue-text ml-1">
                        Sign Up
                      </Link>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Signin.propTypes = {
  onSigninStart: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  onSigninStart: (formData, history) =>
    dispatch(signinStart(formData, history)),
  onSigninStartFB: (user, token, expires_at, history) =>
    dispatch(facebookSignInStart(user, token, expires_at, history)),
  onSigninStartGoogle: (user, token, expires_at, history) =>
    dispatch(googleSignInStart(user, token, expires_at, history))
});

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
