import { connect } from 'react-redux';

import { storeNewUserStart } from '../../store/checkout/checkout.actions';

const mapStateToProps = (state) => ({
  checkout: state.checkout
});

const mapDispatchToProps = (dispatch) => ({
  onStoreNewUser: (user, federated, history, stripe) =>
    dispatch(storeNewUserStart(user, federated, history, stripe))
});
const container = connect(mapStateToProps, mapDispatchToProps);

export default container;
