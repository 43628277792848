import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Container, Row, Col } from 'react-bootstrap';

import WithSpinner from '../../hoc/with-spinner/with-spinner.component';

import GoBack from '../../common/go-back/go-back.component';
import SubscriptionPanel from '../../components/subscription-panel/subscription-panel.component';

import { storeSubscriptionPlan } from '../../store/checkout/checkout.actions';

import config from '../../config';

const Subscription = ({
  subscription: { plans },
  history,
  onStoreSubscriptionPlan
}) => {
  const selectCard = offerId => {
    const selectedPlan = plans.filter(plan => plan.id === offerId)[0];
    onStoreSubscriptionPlan(selectedPlan);
    history.push('/signup/register');
  };

  const redirectLor = () => {
    // route to new page by changing window.location
    const url = config.APP_URL;
    window.location.replace(url);
  };

  return (
    <>
      <Container>
        <GoBack clicked={redirectLor} text="Back to ListenOnRepeat" />
        <Row>
          <Col className="mx-auto py-4 px-3">
            <div className="title text-center">
              <h3>Select a Subscription</h3>
            </div>
            <Row className="justify-content-md-center">
              {plans.length > 0 &&
                plans.map(plan => (
                  <SubscriptionPanel
                    key={plan.id}
                    selectCard={() => selectCard(plan.id)}
                    {...plan}
                  />
                ))}
            </Row>
          </Col>
        </Row>
        <hr />
        <Row className="d-flex align-items-center">
          <Col md="6">
            <small>
              <Link to="/faq">Frequently Asked Questions</Link>
            </small>
          </Col>
          <Col md="6">
            <p className="font-small grey-text d-flex justify-content-end">
              Already a user?
              <Link to="/login" className="blue-text ml-1">
                Login
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = state => ({
  subscription: state.subscription,
  isLoading: state.subscription.isFetching
});

const mapDispatchToProps = dispatch => ({
  onStoreSubscriptionPlan: plan => dispatch(storeSubscriptionPlan(plan))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  WithSpinner
)(withRouter(Subscription));
