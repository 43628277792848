import React from 'react';
import { Col, Card, Button, Badge } from 'react-bootstrap';

const subscriptionPanel = ({
  name,
  price,
  interval,
  selectCard,
  trial_period_days,
  trial,
}) => {
  return (
    <Col md="4" xs="12" className="col-md-auto">
      <Card className="my-3">
        <Card.Body>
          {trial && (
            <Badge variant="info">{trial_period_days}-days free trial</Badge>
          )}
          <Card.Title>{name}</Card.Title>
          <span className="price">
            <small>$</small>
            {parseFloat(price / 100).toFixed(2)}
            <small>/</small>
            <small>{interval}</small>
          </span>
          <hr />
          <ul>
            <li>Listen to music ad-free</li>
            <li>Improved Performance</li>
            {interval === 'year' && <li>2 months free</li>}
          </ul>

          <Button
            style={{ margin: '15px 0px' }}
            variant="danger"
            type="submit"
            className="btn-rounded"
            size="md"
            onClick={selectCard}
            block
          >
            Select
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default subscriptionPanel;
