import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import Signin from '../pages/sign-in/sign-in.component';
import Signup from '../pages/sign-up/sign-up.component';
import ForgotPassword from '../pages/forgot-password/forgot-password.component';
import ResetPassword from '../pages/reset-password/reset-password.component';

import Faq from '../pages/faq/faq.component';
import Success from '../pages/success/success.component';

import Dashboard from '../pages/dashboard/dashboard.container';

const routes = () => (
  <Switch>
    <PrivateRoute exact path="/" component={Dashboard} />
    <PrivateRoute exact path="/dashboard" component={Dashboard} />

    <PublicRoute path="/login" component={Signin} />
    <PublicRoute path="/signup" component={Signup} />
    <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/reset-password" component={ResetPassword} />
    <Route exact path="/faq" component={Faq} />
    <Route exact path="/success" component={Success} />
    <Route exact path="/cancel"  render={() => <Redirect to="/signup/offer" />} />
  </Switch>
);

export default routes;
