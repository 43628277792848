import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

import WithSpinner from '../../hoc/with-spinner/with-spinner.component';
import UserSubscription from '../../components/user-subscription/user-subscription.component';
import ConnectedAccount from '../../components/connected-account/connected-account.component';
import UserDetail from '../../components/user-detail/user-detail.component';
import ConfirmModal from '../../common/confirm-modal/confirm-modal.component';

import {
  cancelSubsStart,
  reactiveSubsStart
} from '../../store/subscription/subscription.actions';

import { changeCardPayment } from '../../store/checkout/checkout.actions';

import config from '../../config';

const Dashboard = ({
  auth: { currentUser },
  user: { user_subscription },
  onCancelSubscription,
  onReactiveSubscription,
  onChangeCardPayment
}) => {
  const [activeShow, setActiveShow] = useState(false);
  const [cancelShow, setCancelShow] = useState(false);

  const stripePromise = loadStripe(config?.stripe?.publicKey);

  const redirectLor = () => {
    // route to new page by changing window.location
    const url = config.APP_URL + '/?sid=' + user_subscription.id;
    window.open(url, '_blank'); //to open new page
  };

  const handleCancelShow = () => setCancelShow(true);
  const handleCancelClose = () => setCancelShow(false);

  const handleActiveShow = () => setActiveShow(true);
  const handleActiveClose = () => setActiveShow(false);

  const cancelSubscriptionHandler = () => {
    onCancelSubscription(
      currentUser.email,
      currentUser.userid || user_subscription.id, // since fb/google user doesnt have userid in cognito
      handleCancelClose
    );
  };

  const reactiveSubscriptionHandler = () => {
    onReactiveSubscription(
      currentUser.email,
      currentUser.userid || user_subscription.id,
      handleActiveClose
    );
  };

  const handleChangePayment = async () => {
    const stripe = await stripePromise;
    onChangeCardPayment(
      user_subscription.customer,
      user_subscription.stripe_subscription,
      stripe
    );
  };

  return (
    <>
      <ConfirmModal
        show={cancelShow}
        handleClose={handleCancelClose}
        heading="Subscription Cancel"
        body="Are you sure you want to cancel your Subscription?"
        clicked={cancelSubscriptionHandler}
      />
      <ConfirmModal
        show={activeShow}
        handleClose={handleActiveClose}
        heading="Subscription Re-Activate"
        body="Are you sure you want to reactivate your Subscription?"
        clicked={reactiveSubscriptionHandler}
      />
      <Container>
        <Row>
          <Col md="6" className="contentItem-sb mx-auto py-4 px-3">
            <Card className="p-3">
              <Card.Body>
                <UserDetail currentUser={currentUser} />
                <hr />

                {user_subscription &&
                  (user_subscription.fbUid || user_subscription.googleUid) && (
                    <>
                      <ConnectedAccount
                        facebook={user_subscription.fbUid}
                        google={user_subscription.googleUid}
                      />
                      <hr />
                    </>
                  )}

                {user_subscription && (
                  <UserSubscription
                    subscription={user_subscription}
                    handleCancelShow={handleCancelShow}
                    handleActiveShow={handleActiveShow}
                    handleChangePayment={handleChangePayment}
                  />
                )}

                <hr />

                <div className="text-center my-3">
                  <Button
                    type="button"
                    variant="danger"
                    block
                    onClick={redirectLor}
                  >
                    Go Back to ListenOnRepeat.com
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onCancelSubscription: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  isLoading: state.user.loading
});

const mapDispatchToProps = (dispatch) => ({
  onCancelSubscription: (email, id, close) =>
    dispatch(cancelSubsStart(email, id, close)),
  onReactiveSubscription: (email, id, close) =>
    dispatch(reactiveSubsStart(email, id, close)),
  onChangeCardPayment: (customer, subscription, stripe) =>
    dispatch(changeCardPayment(customer, subscription, stripe))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  WithSpinner
)(Dashboard);
